<template>
	 <div>
		<label>{{ $t("vehicule.nom") }}</label>
		<b-input type="text" class="form-control" v-model="vehicule_edit.vehicule_name"/>

		<label class="mt-2">{{ $t("vehicule.type") }}</label>
		<e-select
			v-model="vehicule_edit.vehicule_type"
			:options="type_vehicule"
			:searchable="true"
		>
            <template slot="option" slot-scope="{ option }">{{ $t('vehicule.'+option) }}</template>
            <template slot="singleLabel" slot-scope="{ option }">{{ $t('vehicule.'+option) }}</template>
            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
		</e-select>

		<label class="mt-2">{{ $t("vehicule.immatriculation") }}</label>
		<b-input type="text" class="form-control" v-model="vehicule_edit.vehicule_immatriculation"/>

		<label class="mt-2">{{ $t("vehicule.kilometrage") }}</label>
		<b-input type="text" class="form-control" v-model="vehicule_edit.vehicule_kilometrage"/>

		<div class="text-center">
			<b-button v-if="vehicule_edit.vehicule_id"  pill variant="primary" class="mt-3" @click.prevent="saveForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'edit']" /> {{ $t("global.modifier") }}</b-button>
            <b-button v-else  pill variant="primary" class="mt-3" @click.prevent="saveForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t("global.ajouter") }}</b-button>
        </div>
    </div>
</template>

<script type="text/javascript">
import Deplacement from "@/mixins/Deplacement.js"
import Shutter from '@/mixins/Shutter.js'

export default {
	name: 'AddVehicule',
	mixins: [Shutter, Deplacement],
	props: ['vehicule', 'code_onboarding'],
	data () {
		return {
			processing: false,
			edit: false,
			vehicule_edit: {},
			type_vehicule: ['PL', 'PC', 'VAN']
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			if(this.vehicule) {
				this.vehicule_edit = this.vehicule
			}
		},

		async saveForm() {
			this.processing = true
			let vehicule_id = null
			if(this.vehicule_edit.vehicule_id) {
				vehicule_id = this.vehicule_edit.vehicule_id
				await this.editVehicule(this.vehicule_edit.vehicule_id, this.vehicule_edit)
			}
			else {
				let res = await this.createVehicule(this.vehicule_edit)
				vehicule_id = res.retour
			}
			this.vehicule_edit = {}
			this.processing = false
			this.successToast('toast.info_save_succes')
			this.$emit('submit')
			if(this.code_onboarding) {
				const params =  {
					code: this.code_onboarding,
					done: 1,
					skip: 0
				}

				this.ok(params)
				this.shutterPanel().close(this.code_onboarding)
			}
			else {
				this.ok(vehicule_id)
				this.shutterPanel().close('ajout-vehicule')
			}
		}
	},

	components: {
		
	}
}

</script>